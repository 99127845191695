import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { ApiCallsService } from './apicalls.service';
import { UserService } from './user.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private _userService: UserService, private _toastr: ToastrService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log(err);
            if ([401, 403].includes(err.status) && !err.error.includes('login')) {
                // auto logout if 401 or 403 response returned from api
                this._toastr.error('No estás autorizado', 'Error');
                this._userService.logout();
                location.reload();
            }

            const error = err;
            return throwError(() => error);
        }))
    }
}