import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common'; 

import { UserService } from '../../../services/user.service';
import { ApiCallsService } from 'src/app/services/apicalls.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { ConfigService } from 'src/app/services/config.service';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-header-public',
    templateUrl: './header.component.html',
    providers: [UserService,ApiCallsService]
})
export class HeaderPublicComponent implements OnInit, OnDestroy {

    private unsubscribeAll = new Subject<void>();
    public user: any;
    public user$!: Observable<any>;

    viewHeader: boolean = true;
    user_info:any;
    formResultados!: FormGroup;
    showSearchForm: boolean = false;
    showResults: boolean = false;
    global_url:any;
    countdown: any;
    config:any;
    results: Array<any> = [];

    constructor(
        private _userService: UserService,
        private _fb: FormBuilder,
        private _router: Router,
        private _api: ApiCallsService,
        private configService: ConfigService,
        private _location: Location
    ) {
        _location.onUrlChange((url, state) => {
            this.getUser();
            this.comprueboHeader();
        });

        

        moment.locale('es');
        
        this.global_url = environment.apiRoot;

        this.formResultados = this._fb.group({
            busqueda: [''],
        });

    }
    
    ngOnInit() {
        this.getUser();
        this.config = this.configService.getConfig();
        this.comprueboHeader();
    }

    comprueboHeader(){
        if(this._router.url.includes('checkout')) {
            this.viewHeader = false;
        } else {
            this.viewHeader = true;
        }
    }
    ngOnDestroy() {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    getUser() {
        this.unsubscribeAll = new Subject<void>();
        this.user$ = this._userService.user$.pipe(
            takeUntil(this.unsubscribeAll)
        );

        this._userService.fetchUserData();
        this.user$.subscribe({
            next: (user: any) => {
                this.user = user || null;
            },
            error: (error: any) => {
                console.error('User data fetch error:', error);
            }
        });
    }


    showSearch(){
        this.showSearchForm = true;
        
    }

    comprueboToken(){
        if(this.user){
            this._userService.me().subscribe((data: any) => {}, (error: any) => {
                this._userService.logout();
            });
        }

        
    }

    parseDate(date:any){
        var month = moment(date).format('MMMM').substring(0,3);
        var day = moment(date).format('dddd').substring(0,3);
        var day_number = moment(date).format('DD');
        var date_format = day + ', ' + day_number + ' ' + month;
        return date_format;
    }
    
    obtenerEventos(term: any){
        this._api.searchEvents(term).subscribe((data: any) => {
            this.results = data;
            this.showResults = true;
        }, (error: any) => {
            console.log(error);
        });
    }

    // FORM - CLEAR
    resetFormResultados() {
        this.showResults = false;
        this.formResultados.reset();
    }

    // FORM - SEARCHING
    onInputSearch(ev: any): void {
        this.showResults = false;
        if(this.formResultados.value.busqueda != '' && this.formResultados.value.busqueda.length > 3){
            this.obtenerEventos(this.formResultados.value.busqueda);
        }

    }

    goToEvent(slug: any){
        this.showSearchForm = false;
        this._router.navigate(['/evento/' + slug]);
    }

    
    // FORM - FOCUS
    focusFunction() {
        if(!this.formResultados.value.busqueda) {
            this.showResults = false;            
        }
    }
    
    focusOutFunction() {
        console.log(this.formResultados.value.busqueda, !this.formResultados.value.busqueda);
        if(!this.formResultados.value.busqueda) {
            this.resetFormResultados();
        }
    }

}