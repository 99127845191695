import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

export let InjectorInstance: Injector;
import { UserService } from './services/user.service';
import { ApiCallsService } from './services/apicalls.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// TOASTR
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// FORMS
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// LAYOUTS
import { GeneralComponent } from './general/general/general.component';
import { GeneralAdminComponent } from './general/general-admin/general-admin.component';
import { GeneralPublicComponent } from './general/general-public/general-public.component';

import { FooterComponent } from './layouts/layouts-general/footer/footer.component';
import { HeaderAdminComponent } from './layouts/layouts-admin/header/header.component';
import { HeaderPublicComponent } from './layouts/layouts-public/header/header.component';
import { ErrorInterceptor } from './services/error.interceptor';
import { ConfigService } from './services/config.service';
import { catchError, of } from 'rxjs';

export function loadConfig(configService: ConfigService) {
    return () => configService.loadConfig().pipe(
      catchError(() => {
        console.error('Could not load configuration');
        return of({});
      })
    ).toPromise().then(config => {
      configService.setConfig(config);
    });
  }
  
@NgModule({
    declarations: [
        AppComponent,
        GeneralComponent,
        GeneralAdminComponent,
        GeneralPublicComponent,
        HeaderAdminComponent,
        HeaderPublicComponent,
        FooterComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,

        ToastrModule.forRoot(),
        BrowserAnimationsModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        ConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            deps: [ConfigService],
            multi: true
          },
        UserService, ApiCallsService],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private injector: Injector) {
        InjectorInstance = this.injector;
    }
}