import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';

import { GeneralComponent } from './general/general/general.component';
import { GeneralAdminComponent } from './general/general-admin/general-admin.component';
import { GeneralPublicComponent } from './general/general-public/general-public.component';

import { ApiCallsService } from './services/apicalls.service';

const routes: Routes = [
    {
        path: 'login',
        component: GeneralComponent,
        loadChildren: () => import('./pages/public/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'register',
        component: GeneralComponent,
        loadChildren: () => import('./pages/public/register/register.module').then(m => m.RegisterModule)
    },
    {
        path: 'info',
        component: GeneralPublicComponent,
        loadChildren: () => import('./pages/public/legales/legales.module').then(m => m.LegalesModule)
    },
    {
        path: '',
        component: GeneralPublicComponent,
        children: [
            { path: '', loadChildren: () => import('./pages/public/home/home.module').then(m => m.HomeModule) },
            { path: 'evento', loadChildren: () => import('./pages/public/eventos/eventos.module').then(m => m.EventosModule) },
            { path: 'mis-entradas', loadChildren: () => import('./pages/public/entradas/entradas.module').then(m => m.EntradasModule) },
        ],
    }, 
    {
        path: '',
        component: GeneralAdminComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'mi-cuenta', loadChildren: () => import('./pages/admin/mi-cuenta/mi-cuenta.module').then(m => m.MiCuentaModule) },
            { path: 'mis-compras', loadChildren: () => import('./pages/admin/mis-compras/mis-compras.module').then(m => m.MisComprasModule) },
        ]
    }, 
    { 
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
