import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'general-admin',
    templateUrl: './general-admin.component.html',
})
export class GeneralAdminComponent {

    constructor(
        private _router: Router
    ) {
    }

}
