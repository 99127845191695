<div class="wat-general">

    <app-header-admin></app-header-admin>

    <div class="content-general-admin">
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>

</div>