import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from '../../../services/user.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
    selector: 'app-header-admin',
    templateUrl: './header.component.html',
    providers: [UserService]
})
export class HeaderAdminComponent {

    showMenuSecondary: boolean = false;
    config:any;
    constructor(
        private _userService: UserService,
        private configService: ConfigService,
    ) {
        this.config = this.configService.getConfig();
    }

    logout() {
        this._userService.logout();
        location.reload();
    }

}