import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'general',
    templateUrl: './general.component.html',
})
export class GeneralComponent {

    constructor(
        private _router: Router
    ) {
    }

}
